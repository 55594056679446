import { DataTableOptions } from '@/@types/data-table-options';
import {
  OrderListOutput,
  AmsOrderAssignmentStatus,
  AmsOrderStatusEnum,
  PickerListOutput,
  ScanToPickUserStatusEnum
} from '@/api/api';
import { getDispatch } from '@/store/get-dispatch';
import { NwdRootState } from '@/store/nwd-web-root-state';
import { DispatchAction } from '@/store/utility/dispatch-action';
import { TypedModule } from '@/store/utility/module-utility-types';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';

export interface ClerkOrdersGet extends DataTableOptions {
  orderStatus?: AmsOrderStatusEnum;
  orderAssignmentStatus?: AmsOrderAssignmentStatus;
  locationCodes?: string[];
  returnReasons?: string[];
  searchText?: string;
  sortCriteriaDefault?: string[];
}

export interface PickersGet {
  pageSize: number;
  pageNumber: number;
  pickerStatus?: ScanToPickUserStatusEnum;
  searchText?: string;
  sortCriteria?: string[];
}

export const enum ClerkState {
  orders = 'orders',
  orderCount = 'orderCount',
  loading = 'loading',
  pickers = 'pickers',
  pickerCount = 'pickerCount',
  loadingPickers = 'loadingPickers',
  clerkOrdersGet = 'clerkOrdersGet',
  pickersInput = 'pickersInput'
}

interface ClerkStates {
  [ClerkState.orders]: OrderListOutput[];
  [ClerkState.orderCount]: number;
  [ClerkState.loading]: boolean;
  [ClerkState.pickers]: PickerListOutput[];
  [ClerkState.pickersInput]: PickersGet;
  [ClerkState.pickerCount]: number;
  [ClerkState.loadingPickers]: boolean;
  [ClerkState.clerkOrdersGet]: ClerkOrdersGet;
}

export const enum ClerkMutation {
  setOrders = 'setOrders',
  setOrderCount = 'setOrderCount',
  setLoading = 'setLoading',
  setPickers = 'setPickers',
  setPickersInput = 'setPickersInput',
  setPickerCount = 'setPickerCount',
  setLoadingPickers = 'setLoadingPickers',
  setClerkOrdersGet = 'setClerkOrdersGet'
}

interface ClerkMutations {
  [ClerkMutation.setOrders]: OrderListOutput[];
  [ClerkMutation.setOrderCount]: number;
  [ClerkMutation.setLoading]: boolean;
  [ClerkMutation.setPickers]: PickerListOutput[];
  [ClerkMutation.setPickersInput]: PickersGet;
  [ClerkMutation.setPickerCount]: number;
  [ClerkMutation.setLoadingPickers]: boolean;
  [ClerkMutation.setClerkOrdersGet]: ClerkOrdersGet;
}

export const enum ClerkAction {
  getOrders = 'getOrders',
  getPickers = 'getPickers',
  getPickersInput = 'getPickersInput',
  updateClerkOrdersGet = 'updateClerkOrdersGet'
}

interface ClerkActions {
  [ClerkAction.getPickersInput]: () => PickersGet;
  [ClerkAction.getOrders]: () => Promise<void>;
  [ClerkAction.updateClerkOrdersGet]: ({ updates }: { updates: Partial<ClerkOrdersGet> }) => Promise<void>;
  [ClerkAction.getPickers]: ({ input }: { input: PickersGet }) => Promise<void>;
}

export const CLERK_NAMESPACE = 'ClerkState';
export const clerkNamespace = namespace(CLERK_NAMESPACE);
export const dispatchClerkAction: DispatchAction<ClerkActions> =
  getDispatch(CLERK_NAMESPACE);

export type ClerkModule = Override<
  Module<ClerkStates, NwdRootState>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  TypedModule<ClerkStates, ClerkMutations, ClerkActions, {}, NwdRootState>
>;
