import { AmsOrdersClient, AmsOrderStatusEnum, PickersClient } from '@/api/api';
import { ClerkAction, ClerkModule, ClerkMutation, ClerkState, PickersGet } from './clerk-module-type';

const clerkModule: ClerkModule = {
  namespaced: true,
  state: {
    orders: [],
    orderCount: 0,
    loading: false,
    pickers: [],
    pickerCount: 0,
    loadingPickers: false,
    pickersInput: {} as PickersGet,
    clerkOrdersGet: {
      orderStatus: AmsOrderStatusEnum.All,
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
      sortDesc: [],
      sortCriteriaDefault: ['orderStatusCode|asc']
    }
  },
  mutations: {
    [ClerkMutation.setOrderCount](state, payload) {
      state.orderCount = payload;
    },
    [ClerkMutation.setOrders](state, payload) {
      state[ClerkState.orders] = payload;
    },
    [ClerkMutation.setLoading](state, payload) {
      state.loading = payload;
    },
    [ClerkMutation.setPickers](state, payload) {
      state.pickers = payload;
    },
    [ClerkMutation.setPickersInput](state, payload) {
      state.pickersInput = payload;
    },
    [ClerkMutation.setPickerCount](state, payload) {
      state[ClerkState.pickerCount] = payload;
    },
    [ClerkMutation.setLoadingPickers](state, payload) {
      state.loadingPickers = payload;
    },
    [ClerkMutation.setClerkOrdersGet](state, payload) {
      state.clerkOrdersGet = payload;
    }
  },
  actions: {
    async [ClerkAction.getOrders]({ commit, state }) {
      try {
        const {
          itemsPerPage,
          page,
          sortBy,
          sortDesc,
          orderAssignmentStatus,
          locationCodes,
          returnReasons,
          orderStatus,
          searchText,
          sortCriteriaDefault
        } = state.clerkOrdersGet;

        const combinedSortCriteria = ['progressDate|desc'];
        const sortCriteria = sortBy.map(function (value, idx) {
          const desc = sortDesc.find((_, index) => index === idx);
          if (value === 'shipDate') {
            return `${value}|${desc ? 'asc' : 'desc'}`;
          }
          return `${value}|${desc ? 'desc' : 'asc'}`;
        });
        // console.log(
        //   'sortCriteria',
        //   sortCriteria,
        //   sortCriteria && sortCriteria.length > 0
        //     ? [...sortCriteria, ...combinedSortCriteria]
        //     : [...sortCriteriaDefault!, ...combinedSortCriteria]
        // );

        const client = new AmsOrdersClient();
        commit(ClerkMutation.setLoading, true);
        const res = await client.getClerkOrders(
          itemsPerPage,
          page - 1,
          orderStatus,
          orderAssignmentStatus,
          locationCodes,
          returnReasons,
          searchText,
          sortCriteria && sortCriteria.length > 0
            ? [...sortCriteria, ...combinedSortCriteria]
            : [...sortCriteriaDefault!, ...combinedSortCriteria]
        );

        commit(ClerkMutation.setOrders, res.results!);
        commit(ClerkMutation.setOrderCount, res.resultCount);
      } finally {
        commit(ClerkMutation.setLoading, false);
      }
    },

    async [ClerkAction.getPickersInput]({ commit, state }) {
      return state.pickersInput;
    },

    async [ClerkAction.getPickers]({ commit }, { input }) {
      commit(ClerkMutation.setPickersInput, input);

      try {
        const client = new PickersClient();
        commit(ClerkMutation.setLoadingPickers, true);
        const res = await client.getClerkPickers(
          input.pageSize,
          input.pageNumber,
          input.pickerStatus,
          input.searchText,
          input.sortCriteria
        );

        commit(ClerkMutation.setPickers, res.results!);
        commit(ClerkMutation.setPickerCount, res.resultCount);
      } finally {
        commit(ClerkMutation.setLoadingPickers, false);
      }
    },
    async [ClerkAction.updateClerkOrdersGet]({ commit, dispatch, state }, { updates }) {
      commit(ClerkMutation.setClerkOrdersGet, {
        ...state.clerkOrdersGet,
        ...updates
      });
      await dispatch(ClerkAction.getOrders);
    }
  },
  getters: {}
};

export default clerkModule;
